
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class SpeedbizUiTest extends Vue {
  get searchList() {
    return [
      {
        label: "租户名称",
        type: "input",
        value: "",
        prop: "tenantName"
      },
      {
        label: "联系人",
        type: "input",
        value: "",
        prop: "contactName"
      },
      {
        label: "联系人手机号",
        type: "input",
        value: "",
        prop: "mobile"
      },
      {
        label: "功能版本",
        type: "select",
        selectList: [],
        value: "",
        prop: "appType"
      }
    ];
  }
  handleSearch() {
    console.log(1);
  }
}
